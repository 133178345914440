/**
 * Created by jerry on 2020/02/14.
 * 登录api
 */
import * as API from '.'

export default {
    // 登录
    getlogin: params => {
        return API.POST('api/login', params)
    },
    // 路由权限
    getPCPermissionRouter: params => {
        return API.POST('api/account/getPCPermissionRouter', params)
    }
}
